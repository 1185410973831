<template>
  <div
    class="fixed z-40 top-0 bottom-0 left-0 right-0 h-full w-full bg-black bg-opacity-50 flex justify-end"
  >
    <div class="w-3/4 h-full"></div>
    <div class="w-1/4 h-full bg-white rounded-l-2xl p-10 overflow-auto">
      <div class="flex items-center justify-between">
        <h1 class="text-black font-bold text-xl">Release Authorization Form</h1>
        <div
          class="bg-red-500 bg-opacity-30 p-5 rounded-full cursor-pointer"
          @click="$emit('close')"
        >
          <CloseIcon />
        </div>
      </div>
      <ValidationObserver v-slot="{ invalid }">
        <form @submit.prevent="release" class="">
          <h1 class="text-md text-black mt-10">Family Member Authorisation</h1>
          <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
            <div class="mb-5">
              <label class="block mb-2">Name</label>
              <input
                type="text"
                placeholder="Name"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border-red-400 border' : ''"
                v-model="corpse.name"
              />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Relationship"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label class="block mb-2">Relationship</label>
              <input
                type="text"
                placeholder="Relationship"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border-red-400 border' : ''"
                v-model="corpse.relationship"
              />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Signature"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label class="block mb-2">Signature (Complete Name)</label>
              <input
                type="text"
                placeholder="Signature"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border-red-400 border' : ''"
                v-model="corpse.signature"
              />
            </div>
          </ValidationProvider>
          <div class="my-5">TOS - Admin Staff</div>
          <ValidationProvider name="Admin" rules="required" v-slot="{ errors }">
            <div class="mb-5">
              <label class="block mb-2">Admin</label>
              <select
                placeholder="Admin"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border-red-400 border' : ''"
                v-model="corpse.admin_staff_id"
              >
                <option
                  v-for="person in staff"
                  :key="person.staff_id"
                  :value="person.id"
                >
                  {{ person.firstname }} {{ person.lastname }}
                </option>
              </select>
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Relationship"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label class="block mb-2"
                >Signature (Please type in the Admin's full name)</label
              >
              <input
                type="text"
                placeholder="Signature"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border-red-400 border' : ''"
                v-model="corpse.admin_signature"
              />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Signature"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label class="block mb-2">Date</label>
              <input
                type="date"
                placeholder="Signature"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border-red-400 border' : ''"
                v-model="corpse.admin_signature_date"
              />
            </div>
          </ValidationProvider>
          <div class="my-5">TOS - Mortuary Staff</div>
          <ValidationProvider name="Admin" rules="required" v-slot="{ errors }">
            <div class="mb-5">
              <label class="block mb-2">Mortuary Staff</label>
              <input
                type="text"
                placeholder="Admin"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border-red-400 border' : ''"
                v-model="corpse.mortuary_staff_id"
              />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Relationship"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label class="block mb-2">Signature (Complete Name)</label>
              <input
                type="text"
                placeholder="Signature"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border-red-400 border' : ''"
                v-model="corpse.mortuary_staff_signature"
              />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Signature"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label class="block mb-2">Date</label>
              <input
                type="date"
                placeholder="Signature"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border-red-400 border' : ''"
                v-model="corpse.mortuary_signature_date"
              />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Reason"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label class="block mb-2">Reason for Release</label>
              <textarea
                v-model="corpse.reason_for_release"
                placeholder="Burial, e.t.c"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border-red-400 border' : ''"
              >
              </textarea>
            </div>
          </ValidationProvider>
          <button
            class="w-full mt-14 text-white py-3 rounded-md bg-opacity-80 transition-all hover:bg-opacity-100 disabled:cursor-not-allowed"
            :class="invalid ? 'bg-gray-300' : 'bg-primary'"
            :disabled="invalid && submitting"
          >
            {{ submitting ? "Please wait..." : "Proceed to Billing Sheet" }}
          </button>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { createRelease } from "@/services/addmission.js";
import { fetchStaffByBranch } from "@/services/staff.js";

export default {
  name: "corpseProfile",
  components: {
    CloseIcon: () => import("@/assets/icons/CloseIcon.vue"),
  },
  props: {
    corpse_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      corpse: {
        name: "",
        relationship: "",
        signature: "",
        designation: "UNKNOWN",
        admin_staff_id: "",
        admin_signature: "",
        admin_signature_date: "",
        mortuary_staff_id: "",
        reason_for_release: "",
        mortuary_staff_signature: "",
        mortuary_signature_date: "",
      },
      submitting: false,
      staff: [],
    };
  },
  computed: {
    user() {
      return this.$store.state.userDetail.id;
    },
  },
  methods: {
    async release() {
      this.submitting = true;
      const payload = {
        ...this.corpse,
        corpse_id: this.corpse_id,
        mortuary_staff_id: this.user,
      };

      try {
        const response = await createRelease(payload);
        this.$toast.success("Corpse release created");
        this.$emit("proceed", response.data);
      } catch (error) {
        this.$toast.error(error.response.data.message || error);
        this.submitting = false;
      }
    },
    async fetchStaff() {
      try {
        const response = await fetchStaffByBranch(
          this.$store.state.userDetail.branch_id
        );
        this.staff = response.data.staffs;
      } catch (error) {
        console.log(error);
        this.$toast.error(error.response.data.message || error);
      }
    },
  },
  mounted() {
    this.fetchStaff();
  },
};
</script>
